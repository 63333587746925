<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Profile</h2>
        <v-card outlined elevation="2">
            <v-row style="padding:10px;">
                <v-col cols="4" style="padding:20px;">
                    <v-img :src="profile" height="100px" ></v-img>
                </v-col>
                <v-col cols="8">
                    <p style="color: #002060; margin-bottom:0px !important;">{{ user_name }}</p>
                    <p style="color: #002060; margin-bottom:0px !important;"><strong>{{ user_position }}</strong></p>
                    <p style="color: #002060; margin-bottom:0px !important;">{{ user_id }}</p>
                    <router-link to="/safetypassport">
                        <v-btn color="primary" class="mt-1">Safety Passport</v-btn>
                    </router-link>
                </v-col>
            </v-row>
        </v-card>

        <v-card outlined elevation="2" class="mt-5 pa-5">
                <h3 class="primary--text" style="width:100%;">HR</h3>
                <v-row class="primary lighten-5 rounded-lg pa-2 mt-1 ml-1 mr-1">
                    <h2 class="primary--text" style="width:100%;">{{ allowance }}</h2>
                    <p style="color: #002060; margin-bottom:0px !important;width:100%;">Total Allowance</p>
                    <p style="color: #002060; margin-bottom:0px !important;width:100%;">This Month</p>
                </v-row>
                <v-row class="success lighten-5 rounded-lg pa-0 mt-5 ml-1 mr-1">
                    <v-col cols="6">
                        <h3 class="success--text">{{ regular }}</h3>
                        <p>Regular Hours</p>
                    </v-col>
                    <v-col cols="6">
                        <h3 class="primary--text">{{ overtime }}</h3>
                        <p>Over-Time Hours</p>
                    </v-col>
                </v-row>
                <v-row class="rounded-lg pa-0 mt-5 ml-1 mr-1" style="border: 2px solid #002060;">
                    <v-col cols="3">
                        <h3 class="success--text">0</h3>
                        <p>Leave Days</p>
                    </v-col>
                    <v-col cols="3">
                        <h3 class="danger--text">0</h3>
                        <p>Sick Days</p>
                    </v-col>
                </v-row>
        </v-card>
    </v-container>
</template>

<script>

import Swal from 'sweetalert2'
export default {
    name:'Profile',
    data: () => ({
        profile:"https://camusatweb.ubuniworks.com/images/logo/camusat_plain_icon.png",
        user_name: "Loading ...",
        user_position: "",
        user_id: "",
        user_type: "",
        allowance:"Ksh 0",
        regular:0,
        overtime:0,
        user:{}
    }),
    methods:{
        async pullProfile () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    user: this.user.id,
                    type: this.user.type
                })
            };
            const res = await fetch('https://camusatweb.ubuniworks.com/api/v1/auth/profile', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.user_name = data.user.name
                this.user_type = data.type
                this.regular = data.regular
                this.overtime = data.overtime
                this.allowance = "Ksh "+(data.shifts * 200)
                if (data.type == "staff") {
                    this.profile = data.user.passport
                    this.user_position = data.user.position
                    this.user_id = "Staff ID: "+data.user.employee_id
                }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Account not found. Login again',
                    text: data.message
                })
            }
        }
    },
    mounted () {
        this.user = JSON.parse(localStorage.getItem('user'))
        if(this.user == null){
            this.$router.push('/')
        }
        this.pullProfile()

    }

}
</script>